// Inspired by https://github.com/zloirock/core-js/blob/master/packages/core-js/modules/web.url.parse.js

const urlParsePolyfill = (url: string | URL, base?: string | URL): URL | null => {
  try {
    return new URL(url, base);
  } catch {
    return null;
  }
};

window.URL.parse ??= urlParsePolyfill;
